//import i18n from 'i18next';
//import icon_background from '../../../assets/icons/svg/background-image-icon.svg';
//import icon_text from '../../../assets/icons/svg/text-icon.svg';
//import icon_images from '../../../assets/icons/svg/background-image-icon.svg';
import {objectTypes} from '../../../helpers/canvasHelper';
import {
  getImageIcon,
  getPatternIcon,
  getTextIcon,
} from '../../../helpers/iconHelper';

const i18n = {
  t: v => v,
};

export const PHOTO_TAB = 2;
export const TEXT_TAB = 3;
export const BACKGROUND_TAB = 4;

export const getSideBarMenuList = (objects, isPattern, theme) => {
  const bgObject = objects.find(i => i?.type === objectTypes.background);
  const mainMenuList = [
    // {id: 1, img: icon_myDesigns, title: i18n.t('Design')},
    {
      id: PHOTO_TAB,
      img: getImageIcon(theme?.primaryColor),
      title: i18n.t('Photo'),
    },
    {
      id: TEXT_TAB,
      img: getTextIcon(theme?.primaryColor),
      title: i18n.t('Text'),
    },
  ];
  const bgMenuItem = {
    id: BACKGROUND_TAB,
    img: isPattern
      ? getPatternIcon(theme?.primaryColor)
      : getImageIcon(theme?.primaryColor),
    title: isPattern ? i18n.t('Pattern') : i18n.t('Background'),
  };

  return bgObject?.personalization?.length
    ? [bgMenuItem, ...mainMenuList]
    : mainMenuList;
};
